import React, { FC, useEffect } from 'react';
import MarkDown from 'react-markdown';
import { useHistory } from 'react-router-dom';


import MediaContentCard from '../MediaContentCard';
import ActivityExperimentCard from '../ActivityExperimentCard';
import WeblinkCard from '../WeblinkCard';
import ActivityAssessmentCard from '../../components/Assessments/components/AssessmentCardWrapper';

import { ActivityContent } from '../../utils/types';

import styles from './styles.module.scss';
import ActivityJournals from '../ActivityJournals';

import { AdvancedVideo } from '@cloudinary/react';
import { Cloudinary } from '@cloudinary/url-gen';
import { fill } from '@cloudinary/url-gen/actions/resize';
import LinkRenderer from './LinkRenderer';

import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

import { Dispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { actions } from '../../redux/app.slice';
import theme from '../../utils/theme';
import { Divider } from '../InsightWallFilter/components/FilterModal/components/UIElements/index.styles';

interface Props {
  content: ActivityContent | undefined;
  isExperiment?: boolean;
  activityId?: string;
}

const CmsContent: FC<Props> = ({ content, isExperiment = false, activityId }) => {
  const history = useHistory();
  const appInsights = useAppInsightsContext();
  const dispatch: Dispatch<any> = useDispatch();

  const { tracking } = actions;

  useEffect((): (() => void) => {
    window.scrollTo(0, 0);
    return (): void => {};
  }, []);

  if (!content) return null;
  if (!content.body.length) return null;

  const contentCopy = [...content.body];
  if (isExperiment) {
    const blockToMove = 'introductionText';
    contentCopy.unshift(
      contentCopy.splice(
        contentCopy.findIndex((item) => item.blockType === blockToMove),
        1,
      )[0],
    );
  }

  return (
    <>
      {(contentCopy || []).map((block: any, index: number) => {
        const key = `${content.createdAt}${index}`;

        switch (block.blockType) {
          case 'text':
          case 'introductionText':
            return (
              <div key={key} >
                <Divider></Divider>
                <MarkDown className={'markdown'} renderers={{ link: LinkRenderer }} source={block.text} />
              </div>
            );

          case 'video':
            if (block.text.indexOf('https://player.vimeo.com') >= 0) {
              return (
                <div key={key} className={styles.video}>
                  <iframe
                    src={block.text}
                    width="640"
                    height="360"
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    allowFullScreen
                    tabIndex={0}
                  />
                </div>
              );
            } else {
              const cld = new Cloudinary({
                cloud: {
                  cloudName: 'strata-eu',
                },
              });
              const myVideo = cld.video(block.text);
              myVideo.resize(fill().width(640).height(360));
              const hasNewPosterImage: boolean =
              block && block.imageUrl && block.imageUrl.includes('stratav2') && block.imageUrl.includes('images');
              const poster = hasNewPosterImage ? block.imageUrl : myVideo.toURL().split('?').join('.jpg?');

              const videoPlayerProps = {
                cldVid: myVideo,
                poster: poster,
                controls: true,
                controlsList: 'nodownload',
                onPlay: () => {
                  dispatch(
                    tracking({
                      appInsights,
                      name: 'Activity',
                      payload: { type: 'VIDEO_PLAYED', videoId: block.text, activityId },
                    }),
                  );
                },
                onEnded: () => {
                  dispatch(
                    tracking({
                      appInsights,
                      name: 'Activity',
                      payload: { type: 'VIDEO_WATCHED', videoId: block.text, activityId },
                    }),
                  );
                },
                error: () => {
                  dispatch(
                    tracking({
                      appInsights,
                      name: 'Activity',
                      payload: { type: 'VIDEO_ERROR', videoId: block.text, activityId },
                    }),
                  );
                },
              };

              return (
                <div style={{ width: '100%', marginTop: theme.spacing(3), marginBottom: theme.spacing(3) }}>
                  <AdvancedVideo {...videoPlayerProps} />
                </div>
              );
            }

          case 'image':
            return (
              <div key={key} className={styles.image}>
                <a href={block.text} target="_blank" rel="noopener noreferrer">
                  <img src={block.text} alt={block.altText} />
                </a>
              </div>
            );

          case 'media':
          case 'toolkit':
            return <MediaContentCard key={key} mediaId={block.text} />;

          case 'weblink':
            return (
              <WeblinkCard
                key={key}
                _id={block._id}
                title={block.text}
                type={block.blockType}
                url={block.url}
                imageUrl={block.imageUrl}
              />
            );

          case 'insightJournalText':
          case 'journalCapture':
            return isExperiment ? null : <ActivityJournals key={key} block={block} activityId={activityId} />;

          case 'journalPlayback':
            return isExperiment ? null : <ActivityJournals key={key} block={block} activityId={activityId}/>;

          case 'assessment':
            return (
              (process.env.REACT_APP_SHOW_ASSESSMENTS === 'true' && (
                <ActivityAssessmentCard key={key} projectId={block.text} />
              )) ||
              null
            );

          case 'experiment':
            return <ActivityExperimentCard key={key} block={block} activityId={activityId || ''} />;
        }

        return <p key={key}>{block._id}</p>;
      })}
    </>
  );
};

export default CmsContent;
