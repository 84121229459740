import React, { FC, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import NavigationTabButton from '../../components/NavigationTabButton';
import JourneySelectModal from '../../components/JourneySelectModal';
import JourneyPreviewReset from '../../components/JourneyPreviewReset';

import styles from './styles.module.scss';
import HeaderWithLogo from '../../components/Header/HeaderWithLogo/HeaderWithLogo';
import HeaderWithButtons from '../../components/Header/HeaderWithButtons/HeaderWithButtons';
import { showOnPath } from '../../utils/CheckPath';

type Props = {
  journeyTitle: string;
  logout: any;
  roles: Array<string>;
  isFacilitator:boolean;
  isFacilitatorHomePage?:boolean;
};

type NavigationButtonsProps = {
  onClick?: any;
};

const NavigationButtons: FC<NavigationButtonsProps> = ({ onClick }: NavigationButtonsProps) => {
  return (
    <>
      <NavigationTabButton
        label="Journey"
        icon="journey"
        route="/app/journey"
        activeRoutes={['/app/journey', '/app/module/', '/app/activity/']}
        onClick={onClick}
      />
      
      {process.env.REACT_APP_SHOW_ASSESSMENTS === 'true' && (
        <NavigationTabButton label="Assessment" icon="assessment" route="/app/assessments" onClick={onClick} />
      )}

      <NavigationTabButton label="Content" icon="content" route="/app/content" onClick={onClick} />
    </>
  );
};

const NavigationHeader: FC<Props> = ({ journeyTitle, logout, roles, isFacilitator, isFacilitatorHomePage=false }: Props) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showJourneySelect, setShowJourneySelect] = useState(false);
  const [showInsightsWallSettingsModal, setShowInsightsWallSettingsModal] = useState<boolean>(false);
  useLocation();

  const mobileMenuCloseButton = useRef<any>();

  const handleMenuClick = () => {
    setShowMenu(!showMenu);
    if (mobileMenuCloseButton) {
      setTimeout(() => {
        mobileMenuCloseButton.current.focus();
      }, 0);
    }
  };

  return (
    <>

        {!isFacilitator &&
          <JourneySelectModal isOpen={showJourneySelect} onClose={() => setShowJourneySelect(false)} />
        }
      
      {/* Desktop Header */}
      <header className={styles.desktopHeader}>
        <HeaderWithLogo
          roles={roles}
          menu={[showMenu, setShowMenu]}
          logout={logout}
          setShowJourneySelect={setShowJourneySelect}
          insightWall={[showInsightsWallSettingsModal, setShowInsightsWallSettingsModal]}
          isFacilitatorHomePage={isFacilitatorHomePage}
          isFacilitator={isFacilitator}
        />
      </header>
      <JourneyPreviewReset />
    </>
  );
};

export default NavigationHeader;
